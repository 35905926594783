import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SpecsItem from '../../components/specs/specs-item'
import './specs.css'

const specsItems = [
    {
        name: "Water Pipe",
        image: "water_pipe.svg",
        specs: [
            "Porcelain",
            "37mm Mouthpiece",
            "Stainless Steel Key",
            "18mm Titanium Bowl",
            "Titanium Downstem",
            "8.25”H x 7”L x 3”W"
        ],
        copy:  "The key works as a simple lever to lift the bowl, opening up an airway to clear smoke from the chamber. It rotates to accommodate different hand shapes and sizes. The bowl, downstem, and key are one assembly that can be removed for cleaning.",
        patents: [
            "Patent 11317650", 
            "Patent D902367"
        ]
    },
    {
        name: "Mill",
        image: "mill.svg",
        specs: [
            "Porcelain",
            "Stainless Steel",
            "Black Walnut"
        ],
        copy:  "The mill has threadless access, and freespins when processing is complete. It has high capacity, and will not jam.",
        patents: [
            "Patent 11399662",
            "Patent D905769"
        ]
    },
    {
        name: "Container",
        image: "container.svg",
        specs: [
            "Porcelain",
            "Stainless Steel",
            "Black Walnut Inlay",
            "Food Grade Silicone"
        ],
        copy:  "The container has an airtight silicone seal to preserve freshness and holds approximately half an ounce."
    },
    {
        name: "Tealight",
        image: "tealight.svg",
        specs: [
            "Porcelain",
            "Organic Beeswax Candle"
        ],
        copy:  "The tealight will hold any standard size tea candle."
    },
    {
        name: "Wick Holder",
        image: "wick_holder.svg",
        specs: [
            "Borosilicate Glass",
            "Stainless Steel",
            "10ft Hemp Wick"
        ],
        copy:  "The wick holder holds a RAW brand 10 foot wick roll. A lit wick will go out on its own, or can be pulled back into the guide to extinguish.",
        patents: [
            "Patent D929029",
        ]
    },
    {
        name: "Tray",
        image: "tray.svg",
        specs: [
            "Black Walnut",
            "Felt feet",
            "15.5”L x 10”W"
        ],
        copy:  "The tray holds all the components of the set."        
    }
]

const Specs = () => (
  <Layout
    pageClass="specs"
    offsetHeader={true}
  >
    <Seo title="Specs" />
    <h1 className="specs-page-title font-lato">Specs</h1>
    <section className="specs-section">
        <div className="specs-container container-max">
            <div className="specs-items col-3">
                {
                    specsItems.map((specsItem, index) => {
                        return (
                            <SpecsItem 
                                name={specsItem.name}
                                image={specsItem.image}
                                specs={specsItem.specs}
                                copy={specsItem.copy}
                                patents={specsItem.patents}
                                key={`specs-item-${index}`} 
                                itemClass={`spec-${index}`} 
                            />
                        )
                    })
                }                 
            </div>
        </div>
    </section>
  </Layout>
)

export default Specs
