import React from 'react'

function SpecsItem({name, image, specs, copy, patents, itemClass}) {
    return ( 
        <div className={`specs-item ${itemClass}`} >
            <div className="specs-top">                
                <div className="specs-info">
                    <h3 className="specs-name font-lato uppercase">{name}</h3>
                    <ul className="specs">
                        {
                            specs.map((spec, index) => {
                                return (
                                    <li className="spec font-lato" key={`spec-${index}`}><p>{spec}</p></li>
                                )
                            })
                        }
                    </ul>                  
                </div>
                <div className="specs-image-wrapper">
                    <img src={`/img/specs/${image}`} alt={name} className="specs-image" />
                </div>
            </div>
            <div className="specs-bottom">
                {
                    copy &&

                    <p className="specs-copy font-lato">{copy}</p>
                }                  
                {
                    patents &&
                    patents.map((patent) => {
                        return(
                            <p className="specs-patent font-lato" key={`patent-${patent}`}>{patent}</p>
                        )
                    })

                    
                }                
            </div>
        </div>
    )
}

export default SpecsItem